.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e7e7e7;
    /* max-width: 1200px;
    margin: 20px auto; */
}

.header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: 1200px;
    margin: 20px auto; */
}

.logo a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #007bff;
}

.hamburger {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.nav-links {
    display: flex;
    gap: 15px;
}

.nav-links a, .logout-button {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    cursor: pointer;
}

.nav-links a:hover, .logout-button:hover {
    color: #0056b3;
}

.logout-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 10px;
}

.nav-links.open {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    z-index: 1000;
}

.nav-links.open a, .nav-links.open .logout-button {
    display: block;
    padding: 10px 15px;
}

/* Add this for the language buttons */

.language-button {
    background-color: transparent;
    color: #007bff;
    border: 2px solid #007bff;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.language-button:hover {
    background-color: #007bff;
    color: white;
}


/* this is new and made for the menu component adjustment */
.header-menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.categories-nav-item {
    cursor: pointer;
    color: #007bff;
    font-weight: bold;
    padding-right: 15px;
}

.categories-nav-item:hover {
    color: #0056b3;
}

.header-menu .categories {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
}

.header-menu .category-list {
    display: flex;
    gap: 15px;
}


/* Responsive Styles */
@media (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 10px;
        z-index: 1000;
    }
    .categories-nav-item {
        padding-left: 15px;
    }
    .logout-button {
        margin-left: 10px;
        margin-top: 10px;
    }
    .language-button {
        margin-left: 10px;
        margin-top: 10px;
    }

    .hamburger {
        display: block;
    }
}
