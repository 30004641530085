body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    background: #fff;
    padding: 40px 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
}

.content-paragraph {
    text-align: left;
}


/* Headings */
h1 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
    color: #555;
    /* text-transform: uppercase; */
    position: relative;
}

h1::after {
    content: '';
    width: 60px;
    height: 4px;
    background: #007bff;
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
}


h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
    color: #555;
    /* text-transform: uppercase; */
    position: relative;
}

h2::after {
    content: '';
    width: 60px;
    height: 4px;
    background: #007bff;
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
}