/* SendSignInLinkPage.css */

.sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* background-color: #f5f5f5; */
    padding: 20px;
}

.title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.button {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
}
