/* BackButton.css */
.back-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 15px; /* Clean font size */
  font-weight: 500; /* Moderate boldness */
  border-radius: 25px; /* Softer rounded corners */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); 
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;
  text-decoration: none;
  width: auto;
  border: 1px solid #007bff;
  border-radius: 40%;
  color: #007bff;
  background-color: white;
}

.back-button:hover {
  /* background-color: #1976d2;  */
  background-color: rgb(213, 208, 208); 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
  transform: translateY(-2px); 
}

.back-button:active {
  color: white;
  background-color: #007bff; /* Even darker blue on click */
  transform: translateY(1px); /* Slight push down on click */
}

.back-button .icon {
  margin-right: 8px;
  font-size: 16px;
}

.back-button .icon::before {
  content: '←'; /* Left arrow icon */
}

.button-container {
  display: flex;
  justify-content: flex-start; /* Center button */
  margin-top: 20px; /* Add spacing */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .back-button {
    padding: 8px 16px; /* Smaller padding for tablets */
    font-size: 14px;
    border-radius: 20px;
  }

  .back-button .icon {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .back-button {
    padding: 7px 14px;
    font-size: 13px;
    border-radius: 18px;
  }

  .back-button .icon {
    font-size: 12px;
  }
}
