.add-to-radar-container {
    display: flex;
    flex-flow: column;
    padding: 10px;
    border-radius: 10px;
    max-width: 400px;
  }
  .add-to-radar-container p {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #e7e7e7;
  }
  
  .add-to-radar-button {
    /* background-color: #007bff; */
    background-color: #e04050;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
    width: fit-content;
  }

  .add-email-radar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .add-to-radar-button:hover {
    background-color: #0056b3;
  }
  
  .loading-spinner {
    margin-left: 10px;
    font-size: 12px;
  }
  
  .message {
    margin-left: 10px;
    font-size: 12px;
    color: #333;
  }
  
  .error {
    color: #d9534f;
  }
  
  .success {
    color: #5cb85c;
  }
  
  .icon {
    margin-right: 5px;
  }

  
  