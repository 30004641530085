/* General Styles */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

.product-page-container {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    min-height: 100vh;
}

.product-page {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Main product details */
.product-header {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.product-name {
    font-size: 16px;
}

.product-image {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-image img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    padding: 5%;
}

.store-logo {
    max-width: 50px;
    margin-top: 10px;
}

/* .product-info {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */
.product-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    margin-left: 20px;
    flex: 1;
}

.product-info h2 {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    margin-top: 50px;
}

.price-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.discounted-price,
.original-price-only {
    font-size: 22px;
    color: #28a745;
    font-weight: 700;
}

.original-price-discounted {
    color: #dc3545;
    font-weight: 700;
    text-decoration: line-through;
}

.price-history-graph {
    width: 100%;
    max-width: 600px;
    height: 300px;
    margin-top: 20px;
}

canvas {
    width: 100% !important;
    height: auto !important;
}

.product-description {
    margin-top: 20px;
}

.product-description h3 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
}

.product-description p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}

/* Product Availability Section */
.product-availability {
    background: #f9f9f9;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Product Availability Section */
.product-availability h3 {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
}

.product-availability .carousel-item {
    background: #fff;
    padding: 15px;
    margin: 0 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.product-availability .carousel-item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.product-availability .carousel-item h3 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
}

.product-availability .price-box {
    display: flex;
    flex-direction: column;
}

.product-availability .discounted-price,
.product-availability .original-price-only {
    font-size: 14px;
    color: #28a745;
    font-weight: 700;
}

.product-availability .original-price-discounted {
    color: #dc3545;
    font-weight: 700;
    text-decoration: line-through;
    font-size: 14px;
}

/* Product Actions */
.product-actions {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.get-this-item-now {
    background: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    transition: background 0.3s ease;
}

.get-this-item-now:hover {
    background: #0056b3;
}

/* Slick carousel styles */
.slick-slider {
    margin-top: 20px;
}

.slick-list {
    padding: 0 10px;
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.carousel-item {
    background: #fff;
    padding: 15px;
    margin: 0 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.carousel-item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.carousel-item h3 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
}

.carousel-item .price-box {
    display: flex;
    flex-direction: column;
}

.carousel-item .discounted-price,
.carousel-item .original-price-only {
    font-size: 14px;
    color: #28a745;
    font-weight: 700;
}

.carousel-item .original-price-discounted {
    color: #dc3545;
    font-weight: 700;
    text-decoration: line-through;
    font-size: 14px;
}

/* Mobile Styles */
@media (max-width: 767px) {
    .product-info {
        padding: 15px;
        margin-left: 10px;
    }
    .product-header {
        display: flex;
        flex-flow: column;
    }
    .price-history-graph {
        width: 70%;
    }
  }
  @media (max-width: 600px) {
    .price-history-graph {
        width: 100%;
        align-self: center;

    }
  }
    







