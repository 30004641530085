.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    margin: 0 auto;
    max-width: 500px;
}

.title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.textarea {
    min-height: 150px;
    resize: vertical;
    align-self: flex-start;
}

.button {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
}






/* Responsive Design */
/* @media (max-width: 768px) {
    .container {
        padding: 1.5rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .btn-submit {
        width: 100%;
        text-align: center;
    }
} */
