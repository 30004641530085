/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #fff;
    /* padding: 2rem; */
    padding: 1.5rem;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    position: relative;
    text-align: center;
}

/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Modal Category List */
.modal-category-list {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    /* gap: 1rem; */
    gap: 0.75rem;
    /* margin-top: 2rem; */
    margin-top: 1.5rem;
}

/* Modal Category Item */
.modal-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f8f8f8;
    /* padding: 1rem; */
    padding: 0.75rem;
    border-radius: 8px;
    text-decoration: none;
    color: #333;
    transition: transform 0.3s ease;
}

.modal-category:hover {
    transform: scale(1.05);
    background: #ececec;
}

.modal-category .icon {
    /* font-size: 2rem; */
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
}

/* Responsive Design for Small Screens */
@media (max-width: 480px) {
    .modal-content {
        padding: 1rem;
        max-width: 90%;
    }
    .modal-category-list {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        gap: 0.5rem;
    }

    .modal-category {
        padding: 0.5rem;
    }

    .modal-category .icon {
        font-size: 1.5rem;
        margin-bottom: 0.3rem;
    }

    .close-button {
        font-size: 1.2rem;
        top: 5px;
        right: 5px;
    }
}


/* Responsive Design for Medium Screens */
@media (max-width: 768px) {
    .modal-content {
        padding: 1.25rem;
        max-width: 80%;
    }

    .modal-category-list {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        gap: 0.75rem;
    }

    .modal-category {
        padding: 0.5rem;
    }

    .modal-category .icon {
        font-size: 1.5rem;
        margin-bottom: 0.4rem;
    }
}