/* SendSignInLinkPage.css */

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}
