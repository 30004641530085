/* General Styles */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    background: #fff;
    padding: 40px 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
}
.header-menu-container {
    display: flex;
    flex-direction: column;
}

/* Search Bar */
.search-bar {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.search-bar input {
    width: 100%;
    max-width: 600px;
    padding: 15px;
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.search-bar input:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

/* Headings */
h1 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #555;
    /* text-transform: uppercase; */
    position: relative;
}

h1::after {
    content: '';
    width: 60px;
    height: 4px;
    background: #007bff;
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
}


h2 {
    margin-bottom: 20px;
    /* font-size: 24px; */
    font-size: 14px !important;
    font-weight: 400;
    text-align: center;
    color: #555;
    /* text-transform: uppercase; */
    position: relative;
}

h2::after {
    content: '';
    width: 60px;
    height: 4px;
    background: #007bff;
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
}

/* Sections */
.popular-products, .categories, .featured-brands {
    margin-bottom: 40px;
}

/* Lists */
.product-list, .category-list, .brand-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.product, .brand {
    flex: 1 1 calc(25% - 20px);
    min-width: 200px;
    padding: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    text-decoration: none;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.category {
    padding: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 20px;
    text-decoration: none;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product:hover, .category:hover, .brand:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.product img, .brand img {
    /* max-width: 100%; */
    max-width: 40% !important;
    border-radius: 10px;
    margin-bottom: 15px;
}

/* Product Info */
.product h2 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
}

.product div span {
    display: block;
    margin: 5px 0;
    font-size: 16px;
}

.product div span:first-child {
    color: #28a745;
    font-weight: 700;
}

/* .product div span:last-child {
    text-decoration: line-through;
    color: #dc3545;
} */

.product p a {
    display: inline-block;
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border-radius: 25px;
    transition: background 0.3s;
}

.product p a:hover {
    background: #0056b3;
}

.store-name-container {
    color: #007bff;
    font-size: 12px;
}

/* Category Styling */
.category {
    background: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
}

.category:hover {
    background: #ddd;
}

.category-list a {
    text-decoration: none;  /* Remove underline */
    color: #333;            /* Match the text color */
}

.category-list a:hover {
    color: #333;            /* Ensure hover color remains consistent */
}

.featured-products {
    margin: 20px 0;
  }
  
  .slider-container {
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    position: relative;
  }

  /* Enforcing a consistent height for the products in the carousel */
  .slider-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px; /* Uniform height for all slider items */
    padding: 10px;
    box-sizing: border-box;
}

.product-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%; /* Ensure the product box takes up the full height */
    box-sizing: border-box;
    text-align: center;
}

/* Ensure images maintain aspect ratio and fit within constraints */
.product-box img {
    flex-shrink: 0; /* Prevent images from shrinking */
    max-height: 150px; /* Set a max height for the images */
    width: auto;
    object-fit: contain;
    margin-bottom: 10px;
}

/* Ensure the product name fills the remaining space */
.product-box .product-name {
    flex-grow: 1; /* Allow the name to take up remaining space */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Optionally ensure all product names have the same height */
.product-name {
    min-height: 50px; /* Set a minimum height for product names */
    padding: 0 10px;
    box-sizing: border-box;
}

/* Optional: Ensure all items inside the product box have consistent spacing */
.product-box .product-price, .product-box .product-description {
    padding: 10px 0;
}

  .slider-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .category-title {
    text-align: center;
  }

  .slider-item {
    padding: 10px;
    text-align: center;
  }
  
  .slider-item img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .see-all {
    font-size: 0.875rem;
    color: #007bff;
    text-decoration: none;
    align-self: flex-end;
  }
  
  .see-all:hover {
    text-decoration: underline;
  }
  
  #scrollable-container {
    max-height: 500px; /* Set a max height for the scrollable area */
    overflow-y: auto;
}
  

/* Responsive Design */
@media (max-width: 768px) {
    .product, .category, .brand {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .product, .category, .brand {
        flex: 1 1 100%;
    }
}
