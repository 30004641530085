/* Headings */
h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
    color: #555;
    position: relative;
}

h2::after {
    content: '';
    width: 60px;
    height: 4px;
    background: #007bff;
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
}

/* Product Cards */

.product {
    display: flex;
    justify-content: center;
}

.product-box-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    flex: 1 1 calc(25% - 20px);
    max-width: 400px;
    padding: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 100%; 
}
 
/* .product-box-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 calc(25% - 20px);
    max-width: 400px;
    padding: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: auto; 
} */


.product-box-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.product-box-container img {
    max-width: 100px;
    width: 70%;
    border-radius: 10px;
    margin-bottom: 15px;
    flex-shrink: 0; /* Prevent image from shrinking */
}

/* product-box-container Info */

/* Ensure heading in product-box-container is consistent */
.product-box-container h2 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    /* Optionally, set a min-height for consistency */
    min-height: 40px;
    flex-grow: 1; /* Allow to take up remaining space */
}


.product-box-container div span {
    display: block;
    margin: 5px 0;
    font-size: 16px;
}

.product-box-container div span:first-child {
    color: #28a745;
    font-weight: 700;
}
.green {
    color: #28a745;
    font-weight: 700; 
}
.red {
    color: #dc3545 ;
    font-weight: 700; 
}
.alerted-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.alerted-container > p {
    padding-top: 5px;
}
/* .product-box-container div span:last-child {
    text-decoration: line-through;
    color: #dc3545;
} */

.product-box-container .original-price.original-price-only {
    color: #28a745;
    font-weight: 700;
    text-decoration: none;
}

.product-box-container p a {
    display: inline-block;
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 25px;
    transition: background 0.3s;
}

.product-box-container p a:hover {
    background: #0056b3;
}


.product-page-link {
    text-decoration: none;
}

.store-logo {
   width: 100px;
}


.remove-radar-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #dc3545; /* Red color */
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-radar-button:hover {
    background-color: #c82333; /* Darker red on hover */
}
.product-box-container .product-page-link img {
    display: inline-block ;
}


/* Ensure spans in product-box-container div are consistent */
.product-box-container div span {
    display: block;
    margin: 5px 0;
    font-size: 16px;
    line-height: 1.5;
}

.product-box-container div span:first-child {
    color: #28a745;
    font-weight: 700;
}

/* .product-box-container div span:last-child {
    text-decoration: line-through;
    color: #dc3545;
} */

.product-box-container .original-price.original-price-only {
    color: #28a745;
    font-weight: 700;
    text-decoration: none;
}

/* Style for links in product-box-container */
.product-box-container p a {
    display: inline-block;
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 25px;
    transition: background 0.3s;
}

.product-box-container p a:hover {
    background: #0056b3;
}

.product-box-container .product-page-link img {
    display: inline-block;
}

/* Store logo consistency */
.store-logo {
    width: 100px;
}

/* Button consistency */
.remove-radar-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-radar-button:hover {
    background-color: #c82333;
}

/* Optional: Set a minimum height to ensure consistency across all cards */
.product-box-container {
    min-height: 400px; /* Adjust based on your content */
}
