/* styles.css or any CSS file you are using */
.back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
  }
  
  .back-to-top-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
  }
  
  .back-to-top-button:hover {
    background-color: #0056b3;
  }
  