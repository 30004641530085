.banner-container {
    background-image: url('https://res.cloudinary.com/dixsbwjaf/image/upload/t_discount-balloons/gih7fz7nylarwssizfks'); 
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding: 50px 20px;
    border-radius: 8px;
    margin: 20px 0;
    position: relative;
    overflow: hidden;
}

.banner-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Adds a dark overlay for better text readability */
    z-index: 1;
}

.banner-content {
    position: relative;
    z-index: 2;
}

.banner-content h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: rgb(194, 194, 39);
}

.banner-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.banner-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.banner-button:hover {
    background-color: #e03e3e;
}