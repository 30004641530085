.privacy-title {
    text-align: start;
    padding-top: 20px;
}
.privacy-title::after {
    content: '';
    display: none;
}
.privacy-parag {
    text-align: start;
}