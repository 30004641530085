.footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    border-top: 1px solid #e7e7e7;
    text-align: center;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.footer-logo a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #007bff;
}

.footer-links {
    display: flex;
    gap: 20px;
    font-size: 0.875rem;
}

.footer-links a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
}

.footer-links a:hover {
    color: #0056b3;
}

.footer-social {
    display: flex;
    gap: 15px;
}

.footer-social a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
}

.footer-social a:hover {
    color: #0056b3;
}

.footer-bottom {
    margin-top: 10px;
    font-size: 0.875rem;
    color: #6c757d;
}
.socials-logo {
    display: flex;
    flex-direction: column-reverse;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
    }
}
